(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbBffIntegration"), require("sbJsExtends"), require("ReactContainerQuery"), require("ReactDOM"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("gsap"), require("SBTech"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-event-list-presenter", ["React", "mobx", "mobxReact", "mobxUtils", "sbBffIntegration", "sbJsExtends", "ReactContainerQuery", "ReactDOM", "sbInternalMsgBus", "sbRespBlockLib", "sbDataLayer", "gsap", "SBTech"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-event-list-presenter"] = factory(require("React"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbBffIntegration"), require("sbJsExtends"), require("ReactContainerQuery"), require("ReactDOM"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("gsap"), require("SBTech"));
	else
		root["sb-responsive-event-list-presenter"] = factory(root["React"], root["mobx"], root["mobxReact"], root["mobxUtils"], root["sbBffIntegration"], root["sbJsExtends"], root["ReactContainerQuery"], root["ReactDOM"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["sbDataLayer"], root["gsap"], root["SBTech"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__80__, __WEBPACK_EXTERNAL_MODULE__145__, __WEBPACK_EXTERNAL_MODULE__147__) {
return 